var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","lg11":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-between":"","py-2":""}},_vm._l((_vm.navItems),function(item,i){return _c('v-flex',{key:i,attrs:{"px-2":""}},[_c('router-link',{attrs:{"to":'/learnAboutTheReserve?content=' + item.name}},[_c('v-btn',{style:({
                border:
                  _vm.$route.query.content == item.name
                    ? '1px solid #ffe200'
                    : !_vm.$route.query.content && item.name == 'Protection'
                    ? '1px solid #ffe200'
                    : 'none',
              }),attrs:{"block":"","text":"","ripple":false}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"14px","color":"#343434","text-transform":"none"}},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)],1)}),1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.$route.query.content == 'PROTECTION' || !_vm.$route.query.content)?_c('div',[_c('ParambikulamTigerReserve')],1):_vm._e(),(_vm.$route.query.content == 'HABITAT MANAGEMENT')?_c('div',[_c('FDA')],1):_vm._e(),(_vm.$route.query.content == 'CAPTIVE ELEPHANT MANAGEMENT')?_c('StoryOfTheReserve'):_vm._e(),(_vm.$route.query.content == 'ECO TOURISM ACTIVITIES')?_c('div',[_c('KeralaForestDepartment')],1):_vm._e(),(
          _vm.$route.query.content == 'ECO-DEVELOPMENT COMMITTEE/ TRIBAL WELFARE'
        )?_c('div',[_c('OrganisationalChart')],1):_vm._e(),(_vm.$route.query.content == 'Reports')?_c('div',[_c('Reports')],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }